<template>
	<article class="quizes-editor-content__chapters-container">
		<figure class="quizes-editor-content__chapters--parent">
			<v-row>
				<v-col cols="8">
					<span><slot name="question"></slot></span>
				</v-col>
				<v-col cols="4">
					<div class="d-flex flex-row justify-end">
						<span
							><v-icon
								class="text-dark"
								@click="
									$emit('changeIndex', {
										type: 'question',
										mark: -1,
										content: question.content,
										sort: question.sort_order,
										questionId: question.id
									})
								"
								>mdi-chevron-up</v-icon
							></span
						>
						<span
							><v-icon
									class="text-dark"
								@click="
									$emit('changeIndex', {
										type: 'question',
										mark: 1,
										content: question.content,
										sort: question.sort_order,
										questionId: question.id
									})
								"
								>mdi-chevron-down</v-icon
							></span
						>
						<span
							><img class="clickable-icon" src="../../../assets/eva_edit-2-outline.svg" @click="setIsEditing(true)">
						</span>
						<span
							><img class="clickable-icon" src="../../../assets/ci_trash-full.svg" @click="
									$emit('remove', {
										type: 'quiz_question',
										id: questionId
									})
								"></span
						>
					</div>
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<p>{{ content }}</p>
				</v-col>
			</v-row>

		</figure>
		<figure class="quizes-editor-content__chapters--parent quizes-editor-content__chapters--child">
			<v-row>
				<v-col>
					<QuizEditorResponseContent
						v-for="(response, responseIndex) in responses"
						:response="response"
						:question="question.question"
						:key="response.id"
						:index="responseIndex"
						@save="$emit('save', $event)"
						@remove="$emit('remove', $event)"
						@changeIndex="$emit('changeIndex', $event)"
					>
						<template #conditionQueestion>
							<v-row>
								<v-col>
									<QuizEditorQuestionConditionContent
										v-for="condQuestion in response.cond_questions"
										:key="condQuestion.id"
										class="quizes-editor-content__single-chapter-card"
										:question="condQuestion"
										:responseParent="response"
										@save="$emit('save', $event)"
										@remove="$emit('remove', $event)"
										@changeIndex="
											$emit('changeIndex', $event)
										"
									>
									</QuizEditorQuestionConditionContent>
								</v-col>
							</v-row>
						</template>
					</QuizEditorResponseContent>
				</v-col>
			</v-row>
		</figure>
	</article>
</template>

<script>
import { mapGetters } from "vuex";
import QuizEditorResponseContent from "./QuizEditorResponseContent.vue";
import QuizEditorQuestionConditionContent from "./QuizEditorQuestionConditionContent.vue";

export default {
	name: "QuizEditorQuestionContent",
	components: {
		QuizEditorResponseContent,
		QuizEditorQuestionConditionContent
	},
	props: {
		questions: {
			type: Array,
			require: false
		},
		questionIndex: {
			type: Number,
			require: false
		}
	},
	computed: {
		...mapGetters(["isLoading"]),
		question() {
			return this.questions[this.questionIndex]?.question;
		},
		questionId() {
			return this.questions[this.questionIndex]?.id;
		},
		content() {
			return this.question?.content;
		},
		responses() {
			return this.question?.responses;
		}
	},

	data() {
		return {
			rules: {
				required: value => !!value || "Required."
			}
		};
	},
	methods: {
		setIsEditing(val) {
			this.$router.push(
				`/admin/new-quiz/edit-question/${this.question.id}?edit=true&from=${this.$route.name}&quiz=${this.$route.params.id}`
			);
		}
	},
	mounted() {
		this.newContent = this.content;
	}
};
</script>
