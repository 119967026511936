<template>
	<article>
		<QuizEditorQuestionContent
			v-for="(question, questionIndex) in questions"
			:key="question.id"
			:questions="questions"
			:questionIndex="questionIndex"
			@save="$emit('save', $event)"
			@remove="$emit('remove', $event)"
			@changeIndex="$emit('changeIndex', $event)"
		>
			<template #question>
				<b>Question# {{ question.id }}</b>
			</template>
		</QuizEditorQuestionContent>
	</article>
</template>
<script>
import QuizEditorQuestionContent from "./QuizEditorQuestionContent.vue";

export default {
	name: "QuizEditorQuestions",
	components: {
		QuizEditorQuestionContent
	},
	props: {
		questions: {
			type: Array,
			required: true
		}
	},

	data() {
		return {
			rules: {
				required: value => !!value || "Required."
			}
		};
	},
	methods: {}
};
</script>

<style></style>
