var render = function render(){var _vm=this,_c=_vm._self._c;return _c('article',{staticClass:"quizes-editor-content__chapters-container quizes-editor-content__single-chapter-card"},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('span',[_c('b',[_vm._v("Question #"+_vm._s(_vm.questionId))]),_vm._v(" Condition: "+_vm._s(_vm.responseParentContent))])]),_c('v-col',{attrs:{"cols":"4"}},[_c('div',{staticClass:"d-flex flex-row justify-end"},[_c('span',[_c('v-icon',{staticClass:"text-dark",on:{"click":function($event){return _vm.$emit('changeIndex', {
								type: 'question_condition_child',
								mark: -1
							})}}},[_vm._v("mdi-chevron-up")])],1),_c('span',[_c('v-icon',{staticClass:"text-dark",on:{"click":function($event){return _vm.$emit('changeIndex', {
								type: 'question_condition_child',
								mark: 1
							})}}},[_vm._v("mdi-chevron-down")])],1),_c('span',[(!_vm.isEditing)?_c('img',{staticClass:"clickable-icon",attrs:{"src":require("../../../assets/eva_edit-2-outline.svg")},on:{"click":function($event){return _vm.setIsEditing(true)}}}):_c('v-icon',{staticClass:"text-dark",on:{"click":function($event){return _vm.setIsEditing(false)}}},[_vm._v("mdi-close")])],1),_c('span',[_c('img',{staticClass:"clickable-icon",attrs:{"src":require("../../../assets/ci_trash-full.svg")},on:{"click":function($event){return _vm.$emit('removeResponse', _vm.responseId)}}})])])])],1),_c('v-row',[_c('v-col',[(!_vm.isEditing)?_c('p',{staticClass:"ma-0"},[_vm._v(_vm._s(_vm.content))]):_c('div',[_c('InputTextArea',{model:{value:(_vm.newContent),callback:function ($$v) {_vm.newContent=$$v},expression:"newContent"}}),_c('v-btn',{staticClass:"admin-primary-button",attrs:{"loading":_vm.isLoading},on:{"click":_vm.submit}},[_vm._v("Accept")])],1)])],1),_vm._l((_vm.responses),function(response){return _c('v-row',{key:response.id,staticClass:"course-editor-content__chapters-container ml-4 pa-0"},[_c('v-col',{attrs:{"cols":"6 pa-2"}},[_c('p',{staticClass:"ma-0 pa-0"},[_vm._v(" "+_vm._s(response.content)+" ")])]),_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex flex-row justify-end"},[_c('span',[_c('v-icon',{on:{"click":function($event){return _vm.$emit('changeIndex', {
								type: 'response',
								mark: -1
							})}}},[_vm._v("mdi-chevron-up")])],1),_c('span',[_c('v-icon',{on:{"click":function($event){return _vm.$emit('changeIndex', {
								type: 'response',
								mark: 1
							})}}},[_vm._v("mdi-chevron-down")])],1),_c('span',[(!_vm.isEditing)?_c('v-icon',{on:{"click":function($event){return _vm.setIsEditing(true)}}},[_vm._v("mdi-pencil-outline")]):_c('v-icon',{on:{"click":function($event){return _vm.setIsEditing(false)}}},[_vm._v("mdi-close")])],1),_c('span',[_c('v-icon',{on:{"click":function($event){return _vm.$emit('removeResponse', _vm.responseId)}}},[_vm._v("mdi-delete")])],1)])])],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }