<template>
	<article
		class="quizes-editor-content__chapters-container quizes-editor-content__single-chapter-card"
	>
		<v-row>
			<v-col cols="8"
				><span>
					<b>Question #{{ questionId }}</b>
					Condition: {{ responseParentContent }}</span
				></v-col
			>
			<v-col cols="4">
				<div class="d-flex flex-row justify-end">
					<span
						><v-icon
						class="text-dark"
							@click="
								$emit('changeIndex', {
									type: 'question_condition_child',
									mark: -1
								})
							"
							>mdi-chevron-up</v-icon
						></span
					>
					<span
						><v-icon
								class="text-dark"
							@click="
								$emit('changeIndex', {
									type: 'question_condition_child',
									mark: 1
								})
							"
							>mdi-chevron-down</v-icon
						></span
					>
					<span
						><img class="clickable-icon" src="../../../assets/eva_edit-2-outline.svg" v-if="!isEditing" @click="setIsEditing(true)"
							>
						<v-icon v-else class="text-dark" @click="setIsEditing(false)"
							>mdi-close</v-icon
						></span
					>
					<span
						><img class="clickable-icon" src="../../../assets/ci_trash-full.svg" @click="$emit('removeResponse', responseId)"></span
					>
				</div>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<p class="ma-0" v-if="!isEditing">{{ content }}</p>
				<div v-else>
					<InputTextArea v-model="newContent" />
					<v-btn
						@click="submit"
						class="admin-primary-button"
						:loading="isLoading"
						>Accept</v-btn
					>
				</div>
			</v-col>
		</v-row>
		<v-row
			v-for="response in responses"
			:key="response.id"
			class="course-editor-content__chapters-container ml-4 pa-0"
		>
			<v-col cols="6 pa-2">
				<p class="ma-0 pa-0">
					{{ response.content }}
				</p>
			</v-col>
			<v-col cols="6">
				<div class="d-flex flex-row justify-end">
					<span
						><v-icon
							@click="
								$emit('changeIndex', {
									type: 'response',
									mark: -1
								})
							"
							>mdi-chevron-up</v-icon
						></span
					>
					<span
						><v-icon
							@click="
								$emit('changeIndex', {
									type: 'response',
									mark: 1
								})
							"
							>mdi-chevron-down</v-icon
						></span
					>
					<span
						><v-icon v-if="!isEditing" @click="setIsEditing(true)"
							>mdi-pencil-outline</v-icon
						>
						<v-icon v-else @click="setIsEditing(false)"
							>mdi-close</v-icon
						></span
					>
					<span
						><v-icon @click="$emit('removeResponse', responseId)"
							>mdi-delete</v-icon
						></span
					>
				</div>
			</v-col>
		</v-row>
	</article>
</template>
<script>
import InputTextArea from "@/components/InputTextArea.vue";
import { mapGetters } from "vuex";

export default {
	name: "QuizEditorQuestionConditionContent",
	components: {
		InputTextArea
	},
	props: {
		question: {
			type: Object,
			require: true
		},
		responseParent: {
			type: Object,
			require: true
		}
	},
	computed: {
		...mapGetters(["isLoading"]),
		content() {
			return this.question.question.content;
		},
		questionId() {
			return this.question?.question.id;
		},
		responses() {
			return this.question?.question.responses;
		},
		responseId() {
			return this.response?.id;
		},
		responseParentContent() {
			return this.responseParent.content;
		}
	},
	data() {
		return {
			rules: {
				required: value => !!value || "Required."
			},
			isEditing: false,
			newContent: {
				score: this.responseScore,
				isCorrect: this.isCorrect,
				responseText: this.responseText
			}
		};
	},
	methods: {
		setIsEditing(val) {
			this.isEditing = val;
		},
		async submit() {
			await this.$emit("saveResponse", {
				responseId: this.responseId,
				questionId: this.questionId,
				content: this.newContent,
				response: this.response
			});
			this.setIsEditing(false);
		}
	}
};
</script>

<style></style>
