var render = function render(){var _vm=this,_c=_vm._self._c;return _c('article',{staticClass:"quizes-editor-content__chapters-container"},[_c('figure',{staticClass:"quizes-editor-content__chapters--parent"},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('span',[_vm._t("question")],2)]),_c('v-col',{attrs:{"cols":"4"}},[_c('div',{staticClass:"d-flex flex-row justify-end"},[_c('span',[_c('v-icon',{staticClass:"text-dark",on:{"click":function($event){return _vm.$emit('changeIndex', {
									type: 'question',
									mark: -1,
									content: _vm.question.content,
									sort: _vm.question.sort_order,
									questionId: _vm.question.id
								})}}},[_vm._v("mdi-chevron-up")])],1),_c('span',[_c('v-icon',{staticClass:"text-dark",on:{"click":function($event){return _vm.$emit('changeIndex', {
									type: 'question',
									mark: 1,
									content: _vm.question.content,
									sort: _vm.question.sort_order,
									questionId: _vm.question.id
								})}}},[_vm._v("mdi-chevron-down")])],1),_c('span',[_c('img',{staticClass:"clickable-icon",attrs:{"src":require("../../../assets/eva_edit-2-outline.svg")},on:{"click":function($event){return _vm.setIsEditing(true)}}})]),_c('span',[_c('img',{staticClass:"clickable-icon",attrs:{"src":require("../../../assets/ci_trash-full.svg")},on:{"click":function($event){return _vm.$emit('remove', {
									type: 'quiz_question',
									id: _vm.questionId
								})}}})])])])],1),_c('v-row',[_c('v-col',[_c('p',[_vm._v(_vm._s(_vm.content))])])],1)],1),_c('figure',{staticClass:"quizes-editor-content__chapters--parent quizes-editor-content__chapters--child"},[_c('v-row',[_c('v-col',_vm._l((_vm.responses),function(response,responseIndex){return _c('QuizEditorResponseContent',{key:response.id,attrs:{"response":response,"question":_vm.question.question,"index":responseIndex},on:{"save":function($event){return _vm.$emit('save', $event)},"remove":function($event){return _vm.$emit('remove', $event)},"changeIndex":function($event){return _vm.$emit('changeIndex', $event)}},scopedSlots:_vm._u([{key:"conditionQueestion",fn:function(){return [_c('v-row',[_c('v-col',_vm._l((response.cond_questions),function(condQuestion){return _c('QuizEditorQuestionConditionContent',{key:condQuestion.id,staticClass:"quizes-editor-content__single-chapter-card",attrs:{"question":condQuestion,"responseParent":response},on:{"save":function($event){return _vm.$emit('save', $event)},"remove":function($event){return _vm.$emit('remove', $event)},"changeIndex":function($event){return _vm.$emit('changeIndex', $event)}}})}),1)],1)]},proxy:true}],null,true)})}),1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }